import React from 'react';
import './Background.scss';

const Background = props => {
  const scene = props.scene;
  
  return (
    <div className={`Background scene-${scene}`}>
      <div className="Background__bottle"></div>
      <div className="Background__bottom"></div>
    </div>
  );
}

export default Background;