import React, {useState} from 'react';
import Background from './components/Background';
import Logo from './components/Logo';
import Landing from './components/Landing';
import Languages from './components/Languages';
import Menu from './components/Menu';
import Video from './components/Video';


const App = () => {
  
  const scenes = ['landing', 'languages', 'menu'];
  const languages = ['EN', 'FR'];
  const [scene, setScene] = useState(scenes[0]);
  const [language, setLanguage] = useState(languages[0]);
  const [showVideo, setShowVideo] = useState(false);

  const goToScene = scene => {
    if (scenes.includes(scene)) {
      setScene(scene);
    }
  }

  const onLanguageChange = language => {
    if (languages.includes(language)) {
      setLanguage(language);
    }
  }

  const onLandingComplete = () => goToScene('languages');

  const onLanguagesComplete = () => goToScene('menu');

  const onMenuClick = (option) => {
    if (option === 'video') {
      setShowVideo(true);
    }
  }

  const onVideoClose = () => setShowVideo(false);

  return (
    <>
      <Background scene={scene} />
      <Logo scene={scene} />
      <Landing scene={scene} onComplete={onLandingComplete} />
      <Menu scene={scene} language={language} onClick={onMenuClick} />
      <Languages scene={scene} language={language} 
        onChange={onLanguageChange} 
        onComplete={onLanguagesComplete} />
      {showVideo && <Video language={language} onClose={onVideoClose} />}
    </>
  );
}

export default App;
