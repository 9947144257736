import React, { useEffect, useState } from 'react';
import './Landing.scss';

const Landing = props => {
  const scene = props.scene;
  const [frame, setFrame] = useState(0);

  useEffect(() => {

    // Only run on Landing scene
    if (props.scene === 'landing') {

      // Internal animation timmings
      const showText = setTimeout(() => setFrame(1), 100);
      const showFrenchText = setTimeout(() => setFrame(2), 2000);
      const hideText = setTimeout(() => setFrame(3), 4000);
      const showLanguagesMenu = setTimeout(() => props.onComplete(), 4250);
      
      return () => {
        clearTimeout(showText);
        clearTimeout(showFrenchText);
        clearTimeout(hideText);
        clearTimeout(showLanguagesMenu);
      }
    }

  }, [props]);

  return (
    <div className={`Landing scene-${scene} frame-${frame}`}>
      <h2 className="Landing__welcome">Thanks for tapping</h2>
      <h2 className="Landing__welcome--fr">Cliquez pour poursuivre</h2>
      <h3 className="Landing__product">Bourgogne Antik 75cl<br/>BVS 30H60</h3>
    </div>
  );
}

export default Landing;