import React, {useState, useEffect} from 'react';
import './Menu.scss';

const Menu = props => {
  const scene = props.scene;
  const language = props.language;
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    // Only run on Menu scene
    if (props.scene === 'menu') {
      const showMenu = setTimeout(() => setFrame(1), 100);

      return () => {
        clearTimeout(showMenu);
      }
    }
  }, [props])

  return (
    <div className={`Menu scene-${scene} frame-${frame}`}>
      
      <div className="Menu__product">
        Bourgogne Antik 75cl<br/>BVS 30H60
      </div>

      <ul className="Menu__nav">
        <li><a href={
          language === 'FR' ?
          'https://antik-bvs.gossa.puntoquindici.it/?product=fr-FR' : 
          'https://antik-bvs.gossa.puntoquindici.it/?product=en-US'} target="_blank" rel="noopener noreferrer">{
            language === 'FR' ? `Posez-moi une question` : `Ask me a question`
          }</a></li>
        <li><div onClick={() => props.onClick('video')}>{language === 'FR' ? `Vidéo` : `Video`}</div></li>
        <li><a href={process.env.PUBLIC_URL + '/product-sheet.pdf'} target="_blank" rel="noopener noreferrer">{language === 'FR' ? `Fiche produit` : `Product sheet`}</a></li>
        <li><a href={process.env.PUBLIC_URL + '/more-info.pdf'} target="_blank" rel="noopener noreferrer">{language === 'FR' ? `En savoir plus` : `More info`}</a></li>
      </ul>

    </div>
  );
}

export default Menu;