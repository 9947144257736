import React, {useState, useEffect} from 'react'
import './Logo.scss';
import LogoImg from '../assets/images/logo.png'

const Logo = props => {
  const scene = props.scene;
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    // Only run on Landing scene
    if (props.scene === 'landing') {
      const showLogo = setTimeout(() => setFrame(1), 100);

      return () => {
        clearTimeout(showLogo);
      }
    }
  }, [props])

  return (
    <div className={`Logo scene-${scene} frame-${frame}`}>
      <img className="Logo__image" src={LogoImg} alt="logo" draggable="false" />
    </div>
  )
}

export default Logo;