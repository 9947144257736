import React from 'react';
import './Video.scss';

const Video = (props) => {

  return (
    <div className="Video">
      
      <div className="Video__close" 
        onClick={() => props.onClose()}>
        {/* {props.language === 'FR' ? `fermer la vidéo` : `close video`} */}
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M3.515 1.252l8.484 8.484 8.486-8.484a1.6 1.6 0 012.263 2.263l-8.486 8.484 8.486 8.486a1.6 1.6 0 11-2.263 2.263L12 14.262l-8.484 8.486a1.6 1.6 0 01-2.263-2.263L9.736 12 1.252 3.515a1.6 1.6 0 112.263-2.263z" fill="#FFF" fill-rule="evenodd"/></svg>
      </div>

      <iframe className="Video__embed" 
        src="https://player.vimeo.com/video/372413251?autoplay=1&byline=0&portrait=0" 
        title="Video" 
        width="640" 
        height="564" 
        frameBorder="0" 
        allow="autoplay; fullscreen" 
        allowFullScreen></iframe>
    </div>
  );
}

export default Video;