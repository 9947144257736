import React, {useState, useEffect} from 'react';
import './Languages.scss';

const Languages = props => {
  const scene = props.scene;
  const language = props.language;
  const [frame, setFrame] = useState(0);

  const setLanguage = language => {
    // Update language
    props.onChange(language);
    props.onComplete()

    // Animate out and move to next scene
    // if (scene === 'languages') {
    //   setFrame(3);
      
    //   setTimeout(() => {
    //     setFrame(4);

    //     setTimeout(() => props.onComplete(), 500);
    //   }, 1000); 
    // }
  };

  useEffect(() => {
    // Only run on Languages scene
    if (props.scene === 'languages' && frame === 0) {
      const showMenu = setTimeout(() => setFrame(1), 500);
      // const showMenuUnderlines = setTimeout(() => setFrame(2), 700);

      return () => {
        clearTimeout(showMenu);
        // clearTimeout(showMenuUnderlines);
      }
    }
  }, [props, frame])

  const Options = props => {
    return (
      <>
        <li 
          className={props.language === 'EN' ? 'is-active' : ''}
          onClick={() => setLanguage('EN')}>
            {props.short ? `EN` : `English`}
        </li>
        <li 
          className={props.language === 'FR' ? 'is-active' : ''} 
          onClick={() => setLanguage('FR')}>
            {props.short ? `FR` : `Français`}
        </li>
      </>
    );
  };

  return (
    <div className={`Languages scene-${scene} frame-${frame}`}>
      <ul className="Languages__menu">
        <Options language={language} />
      </ul>
      <ul className="Languages__footer">
        <Options language={language} short />
      </ul>
    </div>
  );
}

export default Languages;
